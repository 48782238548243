@import '../../scss/settings/colors';

.button {
    display: inline-block;
    padding: 10px 20px;
    margin: 0 20px 20px 0;
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    border: 1px solid transparent;
    color: $brand-primary;
    text-decoration: none;
    font-family: 'Usual', sans-serif;
    transition: background-color 0.25s ease, border-color 0.25s ease;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.buttonSmall {
    font-size: 14px;
}

.buttonOutline {
    color: #fff;
    background-color: transparent;
    border-color: #fff;

    &:hover,
    &:focus {
        color: $brand-primary;
        background-color: #fff;
    }
}

@each $colorName, $color in $colors {
    .button#{$colorName} {
        background-color: $color;
        border-color: $color;
        color: #fff;

        &:hover,
        &:focus {
            background-color: mix($color, #000, 80%);
            border-color: mix($color, #000, 80%);
        }

        &.buttonOutline {
            color: $color;
            background-color: transparent;
            border-color: $color;

            &:hover,
            &:focus {
                color: #fff;
                background-color: $color;
            }
        }
    }
}
