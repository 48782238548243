$brand-cta: #e0032d !default;
$brand-primary: #022e7c !default;
$brand-secondary: #ecf4ff !default;
$brand-tertiary: #0568ff !default;

$colors: (
    'Cta': $brand-cta,
    'Primary': $brand-primary,
    'Secondary': $brand-secondary,
    'Tertiary': $brand-tertiary
) !default;
